exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-badge-preview-jsx": () => import("./../../../src/pages/badge-preview.jsx" /* webpackChunkName: "component---src-pages-badge-preview-jsx" */),
  "component---src-pages-challenge-contact-jsx": () => import("./../../../src/pages/challenge/contact.jsx" /* webpackChunkName: "component---src-pages-challenge-contact-jsx" */),
  "component---src-pages-challenge-index-jsx": () => import("./../../../src/pages/challenge/index.jsx" /* webpackChunkName: "component---src-pages-challenge-index-jsx" */),
  "component---src-pages-events-hacker-summer-camp-jsx": () => import("./../../../src/pages/events/hacker-summer-camp.jsx" /* webpackChunkName: "component---src-pages-events-hacker-summer-camp-jsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-resources-semgrep-vs-snyk-jsx": () => import("./../../../src/pages/resources/semgrep-vs-snyk.jsx" /* webpackChunkName: "component---src-pages-resources-semgrep-vs-snyk-jsx" */),
  "component---src-pages-solutions-secure-guardrails-jsx": () => import("./../../../src/pages/solutions/secure-guardrails.jsx" /* webpackChunkName: "component---src-pages-solutions-secure-guardrails-jsx" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-case-study-js": () => import("./../../../src/templates/case-study.js" /* webpackChunkName: "component---src-templates-case-study-js" */),
  "component---src-templates-events-index-jsx": () => import("./../../../src/templates/events/index.jsx" /* webpackChunkName: "component---src-templates-events-index-jsx" */),
  "component---src-templates-page-jsx": () => import("./../../../src/templates/page.jsx" /* webpackChunkName: "component---src-templates-page-jsx" */),
  "component---src-templates-resource-hub-js": () => import("./../../../src/templates/resource-hub.js" /* webpackChunkName: "component---src-templates-resource-hub-js" */),
  "component---src-templates-white-paper-jsx": () => import("./../../../src/templates/WhitePaper.jsx" /* webpackChunkName: "component---src-templates-white-paper-jsx" */)
}

